import { local, path } from "d3";
import { ref } from "vue";
import {
  errorServiceHandler,
  toFormUrlEncoded,
  makeQueryStringDync,
  errorHandlerMiddleware,
  setHeader,
  stringifyCaptchaParams,
} from "../../helper";

const actions = {
  listPaymentGateways: (context, payload) => {
    return new Promise((resolve, reject) => {
      let fetchUrl = "";
      let gatewayHeaders = {};
      if (payload) {
        let data = makeQueryStringDync(payload);
        fetchUrl = `${context.getters.vChargeBaseUrl}subscriber/v1/gateway${data}`;
        gatewayHeaders = {
          "X-SESSION": `${localStorage.getItem("sessionToken")}`,
        };
      } else {
        fetchUrl = `${context.getters.vChargeBaseUrl}subscriber/v1/gateway`;
        gatewayHeaders = {
          Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
        };
      }
      fetch(fetchUrl, {
        method: "GET",
        headers: gatewayHeaders,
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  listExternalGateways: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/gateway`, {
        method: "GET",
        headers: setHeader(),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
        },
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  paymentInitiation: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload.params);
      fetch(
        `${context.getters.vChargeBaseUrl}subscriber/v1/payment/init/${payload.gatewayid}`,
        {
          method: "POST",
          body: data,
          headers: setHeader("formUrl"),

          // {
          //   "Content-Type": "application/x-www-form-urlencoded",
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
          // },
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  paymentConfirmation: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(
        `${context.getters.vChargeBaseUrl}callback/v1/${payload.gatewayid}`,
        {
          method: "POST",
          body: JSON.stringify(payload.params.referencedata),
          headers: setHeader("json"),

          // {
          //   "Content-Type": "application/json",
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
          // },
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  initiateRazorpayPayment: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      payload.availabilityid = payload.detail.availabilityset && Array.isArray(payload.detail.availabilityset) ? payload.detail.availabilityset[0] : null;

      var urlencoded = new URLSearchParams();
      urlencoded.append("devicetype", payload.devicetype);
      urlencoded.append("currency", payload.detail.currency);
      urlencoded.append("transactionpurpose", payload.transactionpurpose);
      urlencoded.append("transactionmode", payload.transactionMode);
      urlencoded.append("availabilityid", payload.availabilityid);
      urlencoded.append("planid", payload.detail.planid);

      if (payload.transactionpurpose == "SUBSCRIPTION") {
        urlencoded.append("optrecurring", payload.optrecurring);
      }
      if (payload.couponCode && payload.couponPrice) {
        urlencoded.append("couponid", payload.couponCode);
        urlencoded.append("amount", payload.couponPrice);
      } else {
        urlencoded.append("amount", payload.detail.amount);
      }


      let header = {};
      header["X-SESSION"] = localStorage.getItem("sessionToken");
      fetch(
        `${context.getters.vChargeBaseUrl}subscriber/v1/payment/init/razorpay`,
        {
          method: "POST",
          body: urlencoded,
          headers: header,
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  initiateRazorpayCallback: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }

    var myHeaders = new Headers();
    myHeaders.append("X-SESSION", localStorage.getItem("sessionToken"));
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    let referencedata = JSON.stringify(payload.referencedata);
     let urlencoded = new URLSearchParams();
     urlencoded.append("referenceid", payload.referenceid);
     urlencoded.append("referencedata", referencedata);
     
     var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
      fetch(
        `${context.getters.vChargeBaseUrl}callback/v1/razorpay`, requestOptions
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  telcoPaymentInititation: (context, payload) => {
    return new Promise((resolve, reject) => {
      let data = toFormUrlEncoded(payload.params);
      fetch(
        `${context.getters.vChargeBaseUrl}externalapi/v1/payment/init/${payload.gatewayid}`,
        {
          method: "POST",
          body: data,
          headers: setHeader("formUrl", "deviceToken")
        }
      )
        .then(errorHandlerMiddleware)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  telcoPaymentConfirmation: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload.params);
      fetch(
        `${context.getters.vChargeBaseUrl}externalapi/v1/payment/callback/${payload.gatewayid}`,
        {
          method: "POST",
          body: data,
          headers: setHeader("formUrl", "deviceToken")
        }
      )
        .then(errorHandlerMiddleware)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OTPPaymentConfirmation: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload.params);
      fetch(
        `${context.getters.vChargeBaseUrl}callback/v1/${payload.gatewayid}`,
        {
          method: "POST",
          body: data,
          headers: setHeader("formUrl")
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  telcoPaymentDetail: (context, payload) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${context.getters.vChargeBaseUrl}externalapi/v1/payment/${payload.referenceid}`,
        {
          method: "GET",
          headers: setHeader()
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  listSubscription: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload || !localStorage.getItem("sessionToken")) {
        reject({ reason: "payload not found" });
        return;
      }
      if (payload.subscriptionstatus !== "ALL") {
        let data = makeQueryStringDync(payload);
        fetch(
          `${context.getters.vSmsBaseUrl}subscriberv2/v1/subscription${data}`,
          {
            method: "GET",
            headers: setHeader(),
          }
        )
          .then(errorHandlerMiddleware)
          .then((data) => {
            resolve({ data });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscription`, {
          method: "GET",
          headers: setHeader()
        })
          .then(errorHandlerMiddleware)
          .then((data) => {
            resolve({ data });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },

  listPurchases: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/purchase`, {
        method: "GET",
        headers: setHeader()
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  paymentDetail: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(
        `${context.getters.vChargeBaseUrl}subscriber/v1/payment/${payload.referenceid}`,
        {
          method: "GET",
          headers: setHeader()
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  telcoSTCBH: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(
        `${context.getters.vChargeBaseUrl}pgclienthandler/v1/telcostcbh/accesstoken`,
        {
          method: "POST",
          body: data,
          headers: setHeader("formUrl")
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCardDetails: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        return;
      }

      const data = stringifyCaptchaParams(payload.captcha);

      fetch(
        `${context.getters.vChargeBaseUrl}subscriber/v1/carddetails/${payload.gatewayId}/${data}`,
        {
          headers: setHeader(),
        }
      )
        .then(errorHandlerMiddleware)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  actions,
};
