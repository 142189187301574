import CryptoJS from "crypto-js";
var sha1 = require("sha1");
import { _providerId, _clientKey } from "./provider-config.js";
import { store } from "./store/store";
import { getDeviceOS } from "./store/helper.js";

import firebase from "firebase/app";
import "firebase/analytics";

import moment from 'moment';
import snackbar from "node-snackbar";
import "../node_modules/node-snackbar/dist/snackbar.css";

function encode_url(data) {
  /*eslint-disable */
  var data1 = data.replace(/\+/g, "-");
  /*eslint-disable */
  data1 = data.replace(/\//g, "_");
  /*eslint-disable */
  data1 = data.replace(/\=/g, ",");
  return data1;
}

function Encrypt(deviceid, key) {
  var C = CryptoJS;
  var plainText = C.enc.Utf8.parse(deviceid);
  key = C.enc.Utf8.parse(key);
  var aes = C.algo.AES.createEncryptor(key, {
    mode: C.mode.ECB,
    padding: C.pad.Pkcs7,
    iv: key,
  });
  var encrypted = aes.finalize(plainText);
  encrypted = C.enc.Base64.stringify(encrypted);
  encrypted = encode_url(encrypted);
  return encrypted;
}

export function encryptDeviceInfo() {
  //generating random number

  var uniqueId = localStorage.getItem("random");
  let newUniqueId = "";
  if (!uniqueId) {
    newUniqueId = Math.round(Math.random() * 1e16).toString();
    localStorage.setItem("random", newUniqueId);
  }
  return new Promise((resolve) => {
    let xyz = {
      deviceid: uniqueId ? uniqueId : newUniqueId,
      devicetype: "PC",
      deviceos: getDeviceOS(),
      providerid: _providerId,
      timestamp: Math.floor((new Date()).getTime() / 1000),
      appversion : "46.4.0"
    };

    let res = {
      hash: sha1(JSON.stringify(xyz)),
      enc: Encrypt(JSON.stringify(xyz), _clientKey),
      providerid: _providerId,
    };

    resolve(res);
  });
}

export function getBrowserDetails() {
  var isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  var isMobileDevice = (window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth) < 760;
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var isCookieEnabled = navigator.cookieEnabled;
  var renderEngine = navigator.product;
  var brLanguage = navigator.language;
  var isSilverlightInstalled = false;
  if (navigator.plugins["Silverlight Plug-In"]) {
    isSilverlightInstalled = true;
  }
  var nameOffset, verOffset, ix;
  // In Edge, the true version is after "Edge"
  if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browserName = "Edge";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Opera 15+, the true version is after "OPR/"
  else if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 4);
  }
  // In older Opera, the true version is after "Opera" or after "Version"
  else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In UC Browser, the true version is after "UCBrowser" in userAgent
  else if ((verOffset = nAgt.indexOf("UCBrowser")) !== -1) {
    browserName = "UC";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Samsung Browser, the true version is after "SamsungBrowser" in userAgent
  else if ((verOffset = nAgt.indexOf("SamsungBrowser")) !== -1) {
    browserName = "samsung";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Ios, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("CriOS")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Ios, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("FxiOS")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }
  if ((ix = fullVersion.indexOf(" ")) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }
  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  var returnData = {
    browserName: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
    isMobile: isMobile,
    isIos: isIos,
    br_type: nAgt,
    // userMake: userMake,
    isMobileDevice: isMobileDevice,
    isCookieEnabled: isCookieEnabled,
    renderEngine: renderEngine,
    brLanguage: brLanguage,
    isSilverlightInstalled: isSilverlightInstalled,
  };
  return returnData;
}

export function getOS() {
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var os;
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  if (/Windows/.test(os)) {
    var osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }
  return os;
}

// UID : fSMhjQAmBJTy

export const getDeepLink = (payloadData) => {
  let deeplink = "";

  let data = JSON.parse(payloadData);

  let url = window.location.origin;
  if (data.deeplink && (data.deeplink.includes("http://") || data.deeplink.includes("https://"))) {
    deeplink = data.deeplink;
  }

  // If the Direct Deeplink exists -- contains a Plan and/or coupon ID
  else if (data.deeplink && (data.deeplink.includes("plan") || data.deeplink.includes("planlist"))) {
    let deeplinkSplit = data.deeplink.split("/");

    if(deeplinkSplit[4]) {
      deeplink = `${url}/viewPlanAndCoupon/${deeplinkSplit[4]}`;
  
      if (data.deeplink && data.deeplink.includes("coupon")) {
        deeplink = `${url}/viewPlanAndCoupon/${deeplinkSplit[4]}/${deeplinkSplit[6]}`;
      }
    } else {
      `${url}/viewAllPlans/`;
    }
  }
  // Trailer deeplinking
  else if(data.deeplink && (data.deeplink.includes("trailer") && data.deeplink.includes("play"))){
    let deeplinkSplit = data.deeplink.split("/");
    let contentId = deeplinkSplit[4]
    let trailerId = deeplinkSplit[6]
    deeplink = `${url}/trailerDeeplink/${contentId}?trailerId=${trailerId}`;
  }
  // To navigate to screens
  else if(data.deeplink && data.deeplink.includes("screen")) {
    let deeplinkSplit = data.deeplink.split("/");
    let routeId = deeplinkSplit[3]
    deeplink = `${url}/screen/${routeId}`;
  }

  // Dircect Play deeplinking
  else if(data.deeplink && data.deeplink.includes("play")){
    let deeplinkSplit = data.deeplink.split("/");
    let contentId = deeplinkSplit[4]
    deeplink = `${url}/contentPlayDeeplink/${contentId}`;   
  }
  // If the action is "View" and there is a content ID
  else if (data.action === "VIEW" && data.contentid) {
    deeplink = `${url}/viewContent/${data.contentid}`;
  }

  // If the action is "View" and there is a ticket id
  else if (data.action === "TICKET" && data.ticketid) {
    deeplink = `${url}/viewTicket/${data.ticketid}`;
  }

  // If the action is "View" and there is a plan id and coupon code
  else if (data.action === "REDEEM" && data.planid && data.couponid) {
    deeplink = `${url}/viewPlanAndCoupon/${data.planid}/${data.couponid}`;
  }

  // If the action is "View" and there is a plan id but no coupon code
  else if (
    (data.action === "RENEW" || data.action === "VIEW" || data.action === "UPGRADE") &&
    data.planid &&
    data.couponid
  ) {
    deeplink = `${url}/viewPlanAndCoupon/${data.planid}`;
  }

  return deeplink;
};

export const setNotificationsToLocalStorage = (payload) => {
  let localNotifications = localStorage.getItem("notifications");

  if (localNotifications) {
    localNotifications = JSON.parse(localNotifications);
  } else {
    localNotifications = {};
  }

  let currentUserNotifications = [];

  let userId = store.getters.subscriberid;
  let deviceId = localStorage.getItem("random");

  if (
    store.getters.subscriberid &&
    localNotifications &&
    Object.keys(localNotifications).length > 0 &&
    localNotifications[userId]
  ) {
    currentUserNotifications = localNotifications[userId];
  }

  if (
    !store.getters.subscriberid &&
    localNotifications &&
    Object.keys(localNotifications).length > 0 &&
    localNotifications[deviceId]
  ) {
    currentUserNotifications = localNotifications[deviceId];
  }

  let notificationData = JSON.parse(payload.data);
  let notificationImage = "";

  if (payload.image_url) {
    notificationImage = payload.image_url;
  }

  const messageObject = payload.data;

  let notificationObject = {
    title: payload.title ? payload.title : "",
    message: payload.message ? payload.message : "",
    imageURL: notificationImage ? notificationImage : "",
    contentId: notificationData.contentid ? notificationData.contentid : "",
    cta: notificationData.name ? notificationData.name : "",
    deeplink: getDeepLink(messageObject),
    gcmMessageId: "",
    isRead: false,
    messageId: "",
    recievedDate: Date.now(),
    updatedAt: "",
    userId: store.getters.subscriberid ? store.getters.subscriberid : "",
  };

  if (notificationData.deeplink) {
    notificationObject.isDirectDeeplink = true;
  }

  currentUserNotifications.push(notificationObject);

  if (userId) {
    localNotifications[userId] = currentUserNotifications;
  } else if (deviceId) {
    localNotifications[deviceId] = currentUserNotifications;
  }

  localStorage.setItem("notifications", JSON.stringify(localNotifications));
};

export const getAudioLanguageFromPlayer = () => {
  const menuCell = document.getElementsByClassName("vlive-menu-cell-group");

  const audioArr = menuCell[1];

  let selectedAudio;
  try {
    if (menuCell && menuCell.length) {
      // To get the audio
      for (let singleAudio in audioArr.children) {
        audioArr.children[singleAudio].children.forEach((element) => {
          if (element.getAttribute("activeItem") === "active") {
            selectedAudio = element.getAttribute("codename");
          }
        });
      }
    }
  } catch {
    console.error("element missed");
  }
  return selectedAudio;
};

export const getSubtitleLanguageFromPlayer = () => {
  const menuCell = document.getElementsByClassName("vlive-menu-cell-group");

  const subtitleArr = menuCell[2];

  let selectedSubtitle;

  try {
    // To get the subtitle
    for (let singleSub in subtitleArr.children) {
      subtitleArr.children[singleSub].children.forEach((element) => {
        if (element.getAttribute("activeItem") === "active") {
          selectedSubtitle = element.getAttribute("codename");
        }
      });
    }
  } catch {
    console.error("element missed");
  }
  return selectedSubtitle;
};

export const showSnackBar = (message, btnText) => {
  snackbar.show({
    text: message,
    textColor: "$clr-snackbar-txt",
    pos: "bottom-center",
    actionTextColor: "#de2600",
    actionText: btnText
  });
}

export const setUserPropertiesFunc = (response, subscriptionResponse) => {

  let analyticsObj = {
    age : response.age ? response.age : "NA",
    country: response.subscribercountry ? getCountryName(response.subscribercountry) : "NA",
    dob: response.dob ? response.dob : "NA",
    email: response.email ? response.email : "NA",
    phone: response.mobileno ? response.mobileno : "NA",
    gender: response.gender ? response.gender : "NA",
    identity: response.profileid ? response.profileid : "NA",
    name: response.subscribername ? response.subscribername : "NA" ,
    registration_start_date : response.created ? response.created : "NA",
    subscriber_id : response.subscriberid ? response.subscriberid : "NA", 
    user_id : response.subscriberid ? response.subscriberid : "NA", 
    subscription_status : subscriptionResponse && subscriptionResponse.planstartdate ? "PAID" : "FREE"
  }

  if(subscriptionResponse && subscriptionResponse.nextbilling && subscriptionResponse.planstartdate) {
    analyticsObj.subscription_end_date = subscriptionResponse && subscriptionResponse.nextbilling ? subscriptionResponse.nextbilling : "NA";
    analyticsObj.subscription_start_date = subscriptionResponse && subscriptionResponse.planstartdate ? subscriptionResponse.planstartdate : "NA";
  }

  const googleAnalytics = firebase.analytics();
  googleAnalytics.setUserProperties(analyticsObj)
}

export const getCountryName = (countryCode) => {
  const countriesArr = store.getters.getCountryListByCode;

  let countryName;
  countriesArr.forEach(country => {
    if (country.code === countryCode) {
      countryName = country.name;
      return;
    }
  });

  return countryName;
};

export const timeDifference = (pre) => {

 let current = moment.utc()
let previous = moment.utc(pre)
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;

  var elapsed = current - previous;

  if (elapsed < msPerDay ) {
    return moment(previous).fromNow(); 
  } else {
    return moment(previous).format('LLL');
  }
}
